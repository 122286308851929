!function(){
    FormData.prototype.submit = async function(form_attribute = {}){
        const form = document.createElement("form")
        Object.entries(form_attribute).forEach(([k, v]) => {
            form.setAttribute(k, v)
        })
        form.hidden = true

        const inputs = await Promise.all([...this.entries()].map(([k, v]) => new Promise((ok, fail) => {
            const input = document.createElement("input")
            input.name = k

            if(typeof v === "string"){
                input.value = v
                ok(input)
            }else if(v instanceof File){
                const file = v
                if(file.name === "" && file.type === ""){
                    input.value = ""
                    ok(input)
                    return
                }
                const reader = new FileReader()
                reader.onload = eve => {
                    input.value = JSON.stringify({
                        lastModified: file.lastModified,
                        name: file.name,
                        size: file.size,
                        type: file.type,
                        data: reader.result
                    })
                    ok(input)
                }
                reader.onerror = eve => {
                    fail()
                }
                reader.readAsDataURL(file)
            }
        })))

        form.append(...inputs)
        document.body.append(form)
        form.submit()
        form.remove()
    }
}()
