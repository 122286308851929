jQuery(document).ready(function() {

    const loading=$('.loader-wrap');

    function ajaxLoad(filename, content, cb) {
        content = typeof content !== 'undefined' ? content : 'main-content';
        loading.show();
        // filename = encodeURI(filename);
        axios.get(filename).then(response => {
            if (typeof cb !== 'undefined') {
                cb(response.data, content, filename);
                // console.log('cb');
            } else {
                // console.log('pjax');

                /*
                innerHTMLでの書き換えだと、書き換え後に読み込まれたjsを実行できない。
                 */
                //                var target = document.getElementById(content);
                //            target.innerHTML=response.data;

                // console.log(content);
                $('#' + content).html(response.data);

                if (content === 'main-content') {
                    history.pushState({ page: response.data }, null, filename);
                }
                $('body,html').animate({
                    scrollTop: 0
                }, 0);

                // console.log(filename);
                const splited = filename.split('/');
                if (splited[splited.length - 1] === 'home') {
                    // console.log('loadtask');
                    loadTask();
                }

            }

            loading.hide();

        }).catch(error => {
            loading.hide();
            console.log(error);
            console.log('error:', error.response);
            if (error.response.data.message === "Your email address is not verified.") {
                location.href = '/email/verify';
            } else if (error.response.data.message === "Unauthenticated.") {
                location.href = '/login';
            }
        });
    }


    function ajaxForm(formid, content, cb) {
        formid = typeof formid !== 'undefined' ? formid : 'ajaxForm';
        content = typeof content !== 'undefined' ? content : 'main-content';
        loading.show();
        const form = document.getElementById(formid);
        const data = new FormData(form);

        //フォームの中身を確認
        //  for (value of data.entries()) {
        //      console.log(value);
        //  }

        const url = form.action;
        axios.post(url, data).then(response => {
            // console.log('response;', response);

            form.classList.remove("is-invalid");
            /*
            https://stackoverflow.com/questions/4777077/removing-elements-by-class-name
            class名で子要素を削除する
             */
            const elements = document.getElementsByClassName('invalid-feedback');
            while (elements.length > 0) {
                elements[0].parentNode.removeChild(elements[0]);
            }

            if (response.data.redirect_url) {
                // console.log('redirect;');
                ajaxLoad(response.data.redirect_url, content);
            } else {
                loading.hide();
                // console.log('html;');
                /*
             innerHTMLでの書き換えだと、書き換え後に読み込まれたjsを実行できない。
              */
                // var target = document.getElementById(content);
                // target.innerHTML = response.data.html;
                // $('#'+content).html(cb(response.data));
                if (typeof cb === 'function') {
                    cb(formid, content, response.data);
                } else {
                    const obj = document.getElementById(content);
                    obj.innerHTML = response.data;
                }
            }
        }).catch(error => {
            //     console.log('err:'+ error);
            /*
            https://github.com/axios/axios/issues/960
            axios の catchでerrorをlonsole.logに出力するときはerror.responseとすること
             */
            // console.log('error:',error.response);
            loading.hide();

            form.classList.remove("is-invalid");
            const elements = document.getElementsByClassName('invalid-feedback');
            while (elements.length > 0) {
                elements[0].parentNode.removeChild(elements[0]);
            }

            let forcus = false;
            for (const control in error.response.data.errors) {
                // console.log(control);
                const obj = document.getElementById(control);
                obj.classList.add("is-invalid");
                if (!forcus) {
                    obj.focus();
                    forcus = true;
                }
                const span = document.createElement('span');
                span.setAttribute('class', 'invalid-feedback');
                span.setAttribute('role', 'alert');
                const strong = document.createElement('strong');
                strong.innerText = error.response.data.errors[control];
                span.appendChild(strong);
                obj.parentNode.appendChild(span);
                // document.getElementById('error-' + control).innerHTML(error.response.data.errors[control]);
            }
        });
    }

    $(document).on('click', 'a.page-link, .ajax-link', function(event) {
        event.preventDefault();
        ajaxLoad($(this).attr('href'), $(this).attr('data-content'));
    });

    $(document).on('submit', '.ajax-form', function(event) {
        event.preventDefault();
        ajaxForm($(this).attr('id'), $(this).attr('data-content'));
    });

    $(document).on('click', '.ajax-update-status', function(event) {
        event.preventDefault();
        var that=$(this);
        ajaxLoad($(this).attr('href'), $(this).attr('data-content'),function (data, content, filename){
            $('#' + content).text(data.data);
            that.closest('td').find('a').each(function (){
                $(this).css('pointer-events','none');
            });
            that.closest('td').find('button').each(function (){
                $(this).prop('disabled',true);
            });
        });
    });

    //Datepickerにクリアボタンを追加
    // @see https://www.okushin.co.jp/kodanuki_note/2020/02/jquery-ui-datepicker%E3%82%92%E3%81%AB%E3%82%AF%E3%83%AA%E3%82%A2%E3%83%9C%E3%82%BF%E3%83%B3%E3%82%92%E8%BF%BD%E5%8A%A0%E3%81%97%E3%81%A6%E3%82%B9%E3%83%9E%E3%83%9B%E3%81%A7%E3%82%82%E4%BD%BF%E3%81%84.html
    const setCalsClearButton = function(year,month,elem){
        const afterShow = function(){
            const d = new $.Deferred();
            let cnt = 0;
            setTimeout(function(){
                if(elem.dpDiv[0].style.display === "block"){
                    d.resolve();
                }
                if(cnt >= 500){
                    d.reject("datepicker show timeout");
                }
                cnt++;
            },10);
            return d.promise();
        }();

        afterShow.done(function(){

            // datepickerのz-indexを指定
            $('.ui-datepicker').css('z-index', 2000);

            const buttonPane = $( elem ).datepicker( "widget" ).find( ".ui-datepicker-buttonpane" );

            const btn = $('<button class="ui-datepicker-current ui-state-default ui-priority-primary ui-corner-all" type="button">Clear</button>');
            btn.off("click").on("click", function () {
                $.datepicker._clearDate( elem.input[0] );
            });
            btn.appendTo( buttonPane );
        });
    }

    $('.datepicker').datepicker({
        showButtonPanel: true,
        dateFormat:'yy-mm-dd',
        beforeShow : function(inst,elem){
            setCalsClearButton(null,null,elem);
        },
    });

    $(".month-day-picker").datepicker( {
        showButtonPanel: true,
        changeYear: false,
        dateFormat: 'mm-dd',
        yearSuffix:'',
        beforeShow : function(inst,elem){
            setCalsClearButton(null,null,elem);
        },
    }).focus(function () {
        $(".ui-datepicker-year").hide();
        $('.ui-datepicker-calendar thead').hide();
    });

    $(document).on('change','.user-master-switch', function (e){
        const id=$(this).data('id');
        const type=$(this).data('type');
        const value=$(this).prop('checked')?'1':'0';
        const url=$(this).data('url');
        const data = new FormData();
        loading.show();
        data.append('id',id);
        data.append('type',type);
        data.append('value',value);
        axios.post(url, data).then(response => {
            loading.hide();
        }).catch(error => {
            loading.hide();
            console.log('error:',error.response);
        });
    });

    $('.sortable').sortable({
        update: function(ev, ui) {
            loading.show();
            const order = $(this).sortable("toArray", { attribute: 'data-id' }).join(",");
            console.log(order);
            const data = new FormData();
            const url = $(this).data('url');
            data.append('order', order);

            axios.post(url, data).then(function(response) {
                // console.log(response);
               loading.hide();
            }).catch(function(error) {
                loading.hide();
                console.log('error:' + error.response);
            });
        }
    });

    $(document).on('click', '.btn-clipboard',function (e){
        const target=$(this).data('target');
        const range = document.createRange();
        range.selectNode(document.getElementById(target));
        const copiedText=range.cloneContents().textContent;
        navigator.clipboard.writeText(copiedText);

        $(this).children('.copy-before').addClass('hidden').delay(1000).queue(function (next) {
            $(this).removeClass('hidden');
            next();
        });
        $(this).children('.copied').removeClass('hidden').delay(1000).queue(function (next) {
            $(this).addClass('hidden');
            next();
        });
    })

    $('.datetimepicker').datetimepicker({
        step:10, // 10分刻み
        format:'Y-m-d H:i:00',
    });
});
