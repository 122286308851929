window._ = require('lodash');

try {
    bootstrap=require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

var toastElList = [].slice.call(document.querySelectorAll(".toast"));
var toastList = toastElList.map(function (toastEl) {
    return new bootstrap.Toast(toastEl, {
        // オプション
        delay: 10000,
    });
});

toastList.forEach(function (obj){
    obj.show();
})


window.$ = window.jQuery = require('jquery');
require('jquery-ui/ui/core');
require('jquery-ui/ui/widget');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-datetimepicker')
require('./jquery.ui.datepicker-ja');
require('jquery-ui/ui/widgets/mouse');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/widgets/autocomplete');

$.datetimepicker.setLocale('ja');


var receiptElm=document.getElementById('receipt-modal');
if(receiptElm) {
    const myModal = new bootstrap.Modal(receiptElm, {
        keyboard: false
    });

    const receiptTextList = [].slice.call(document.querySelectorAll('.receipt-text'));
    receiptTextList.forEach(function (receiptText) {
        receiptText.addEventListener('click', function (event) {
            document.getElementById('receipt_id').value = event.currentTarget.dataset.id;
            if(event.currentTarget.dataset.memo) {
                document.getElementById('receipt_memo').value = event.currentTarget.dataset.memo;
            }
            const form = receiptText.closest('form');
            if(form){
                const num = form.getElementsByClassName("division-number");
                if(num) {
                    document.getElementById('receipt_number').value = num[0].options[num[0].selectedIndex].value;
                }else {
                    document.getElementById('receipt_number').value = event.currentTarget.dataset.number;
                }
            }else {
                document.getElementById('receipt_number').value = event.currentTarget.dataset.number;
            }
            myModal.show();
        })
    });

    const receiptButtonList = [].slice.call(document.querySelectorAll('.receipt-button'));
    receiptButtonList.forEach(function (receiptButton) {
        receiptButton.addEventListener('click', function () {
            const msg = document.getElementById('tiny-message');
            msg.textContent = 'Waiting...';
            msg.style.display = 'block';
            const form = document.getElementById('receipt-form');
            form.submit();
            msg.textContent = 'done.';
            setTimeout(function () {
                msg.style.display = 'none';
                myModal.hide();
            }, 2000);
        })
    });
}
